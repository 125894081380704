import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  InfoItemHorizontal
} from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert } from "../../../utilities";
import { Formik } from "formik";
import { KegiatanApi } from "../../../api";

const DetailKegiatan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_kegiatan, tab } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListSO, setDataListSO] = useState([]);
  const [dataKegiatan, setDataKegiatan] = useState({});
  const [data, setData] = useState({ sumber_daya: [] });
  const [approveStatus, setApproveStatus] = useState("V");
  const [dataApproval, setDataApproval] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([KegiatanApi.getSingle({id_kegiatan: id})])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior ? data.data.data.behavior : "V";
          setDataKegiatan(detail);
          setDataApproval(approval ?? []);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                  ? "Reject"
                  : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    console.log(approveStatus);

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Kegiatan</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {tab !== 'history' && <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Kegiatan</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve Kegiatan</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Kegiatan</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: <span className="text-warning">Revise Kegiatan</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve Kegiatan</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>}
        </Card.Body>
      </Card>
    );
  };

  const DetailData = () => {
    return (
      <>
        <Row>
          <Col md>
            {/* Nama program */}
            <InfoItemHorizontal 
              label="Nama. Program"
              text={dataKegiatan.program?.nama_program ?? '-'}
            />
            {/* Tgl. program */}
            <InfoItemHorizontal 
              label="Tgl. Program"
              text={dataKegiatan.program?.tgl_program ? DateConvert(new Date(dataKegiatan.program?.tgl_program)).detail : '-'}
            />
            {/* No. program */}
            <InfoItemHorizontal 
              label="No. Program"
              text={dataKegiatan.program?.no_program ?? '-'}
            />
            {/* Unit organisasi */}
            <InfoItemHorizontal 
              label="Unit Organisasi"
              text={dataKegiatan.program?.nama_unit_organisasi ?? '-'}
            />
          </Col>
          <Col md>
            {/* Penanggung jawab */}
              <InfoItemHorizontal 
              label="Nama Penanggung Jawab"
              text={dataKegiatan.program?.nama_karyawan ?? '-'}
            />
            {/* Periode mulai */}
            <InfoItemHorizontal 
              label="Periode Mulai"
              text={dataKegiatan.program?.periode_mulai ? `${DateConvert(new Date(dataKegiatan.program?.periode_mulai)).detailMonth} ${DateConvert(new Date(dataKegiatan.program?.periode_mulai)).defaultYear}` : '-'}
            />
            {/* Periode selesai */}
            <InfoItemHorizontal 
              label="Periode Selesai"
              text={dataKegiatan.program?.periode_selesai ? `${DateConvert(new Date(dataKegiatan.program?.periode_selesai)).detailMonth} ${DateConvert(new Date(dataKegiatan.program?.periode_selesai)).defaultYear}` : '-'}
            />
            {/* Deskripsi */}
            <InfoItemHorizontal 
              label="Deskripsi"
              text={dataKegiatan.program?.deskripsi_program ?? '-'}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <InfoItemHorizontal
              label="Tanggal Kegiatan :"
              text={dataKegiatan.tgl_kegiatan ? DateConvert(new Date(dataKegiatan.tgl_kegiatan)).detail : '-'}
            />
            <InfoItemHorizontal label="No. kegiatan :" text={dataKegiatan.no_kegiatan ?? '-'} />
            <InfoItemHorizontal label="Nama Kegiatan :" text={dataKegiatan.nama_kegiatan} />
            <InfoItemHorizontal
              label="Penanggung Jawab :"
              text={dataKegiatan.nama_karyawan}
            />
          </Col>
          <Col lg={6}>
            <InfoItemHorizontal
              label="Periode Mulai :"
              text={dataKegiatan.periode_mulai ? DateConvert(new Date(dataKegiatan.periode_mulai)).detail : '-'}
            
            />
            <InfoItemHorizontal
              label="Periode Selesai :"
              text={dataKegiatan.periode_selesai ? DateConvert(new Date(dataKegiatan.periode_selesai)).detail : '-'}
            />
            <InfoItemHorizontal label="Keterangan :" text={dataKegiatan.deskripsi_kegiatan ?? '-'} />
          </Col>
        </Row>
        <hr />
        <InfoItemHorizontal
          label="Jenis Anggaran :"
          text={dataKegiatan.nama_jenis_anggaran ?? '-'}
        />
        <InfoItemHorizontal 
          label="Kelompok Anggaran :" 
          text={dataKegiatan.nama_kelompok_anggaran ?? '-'} 
        />
        <InfoItemHorizontal
          label="Sub Kelompok Anggaran :"
          text={dataKegiatan.nama_sub_kelompok_anggaran ?? '-'}
        />
      </>
    )
  };

  useEffect(() => {
    setNavbarTitle("Approval Kegiatan");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Kegiatan</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <DetailData />
            </>
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan"),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataKegiatan.no_transaksi,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataKegiatan.baseline,
          };

          KegiatanApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/kegiatan", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${modalConfig.type == "APP"
                    ? "Approve"
                    : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                    } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            <FormCard
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              validateForm={validateForm}
              dirty={dirty}
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailKegiatan;
