import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import { 
  InfoItemHorizontal, RadioButton, TextArea
} from 'components'
import { 
  DateConvert 
} from 'utilities'

const InfoSection = ({dataInfo}) => {
  return (
    <Row>
      <Col md="6">
        <InfoItemHorizontal 
          width={150}
          label="Tgl. Sub Kegiatan"
          text={dataInfo?.sub_kegiatan?.tgl_sub_kegiatan ? DateConvert(new Date(dataInfo.sub_kegiatan.tgl_sub_kegiatan)).detail : '-'}
        />
        <InfoItemHorizontal 
          width={150}
          label="No. Sub Kegiatan"
          text={dataInfo.no_sub_kegiatan}
        />
        <InfoItemHorizontal 
          width={150}
          label="Nama. Sub Kegiatan"
          text={dataInfo.nama_sub_kegiatan}
        />
        <InfoItemHorizontal 
          width={150}
          label="Penanggung Jawab"
          text={dataInfo.nama_penanggung_jawab}
        />
        {console.log('dataInfo', dataInfo)}
      </Col>
      <Col md="6">
        <InfoItemHorizontal 
          width={150}
          label="Periode Awal"
          text={dataInfo.tgl_mulai_sub_kegiatan ? DateConvert(new Date(dataInfo.tgl_mulai_sub_kegiatan)).detail : '-'}
        />
        <InfoItemHorizontal 
          width={150}
          label="Periode Akhir"
          text={dataInfo.tgl_selesai_sub_kegiatan ? DateConvert(new Date(dataInfo.tgl_selesai_sub_kegiatan)).detail : '-'}
        />
        <InfoItemHorizontal 
          width={150}
          label="Kegiatan"
          text={dataInfo.nama_sub_kegiatan}
        />
      </Col>
      <Col md="6" className="mt-2">
        <TextArea 
          readOnly
          label="Keterangan Sub Kegiatan"
          value={dataInfo?.sub_kegiatan?.keterangan ?? '-'}
          rows="4"
        />
      </Col>
      <Col md="6" className="d-flex flex-column mt-2">
        <RadioButton 
          checked={dataInfo?.sub_kegiatan?.is_aset ? true : false}
          label="Aset"
          disabled
        />

        <RadioButton 
          checked={dataInfo?.sub_kegiatan?.is_aset ? false : true}
          label="Non Aset"
          disabled
        />
      </Col>
    </Row>
  )
}

export default InfoSection
