import Services from "services";

class ApprovalProgramApi {
  get(params) {
    return Services.get("approval_program/list", { params });
  }

  getHistory(params) {
    return Services.get("approval_program/history/list", { params });
  }

  getSingle(params) {
    return Services.get("approval_program/detail", { params });
  }

  approve(data) {
    return Services.post("approval_program/approve", data);
  }
}

export default new ApprovalProgramApi();
