import Services from "../../services";

class PengakuanHutangApi {
  // API SERVER
  get(params) {
    return Services.get("approval_pemasukan/list", { params });
  }

  getHistory(params) {
    return Services.get("approval_pemasukan/history/list", { params });
  }

  getSingle(params) {
    return Services.get("approval_pemasukan/detail", { params });
  }

  approve(data) {
    return Services.post("approval_pemasukan/approve", data);
  }
}

export default new PengakuanHutangApi();
