import Services from "services";

class PPAApi {
  // API SERVER
  get(params) {
    return Services.get("approval_rka/list", { params });
  }

  getHistory(params) {
    return Services.get("approval_rka/history/list", { params });
  }

  getSingle(params) {
    return Services.get("approval_rka/detail", { params });
  }

  approve(data) {
    return Services.post("approval_rka/approve", data);
  }
}

export default new PPAApi();
