import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Table,
  Th,
  Td,
  TdFixed,
} from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert } from "../../../utilities";
import { Formik } from "formik";
import { FakturPenjualanApi } from "../../../api";

const DetailFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_faktur, tab = "" } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListFP, setDataListFP] = useState([]);
  const [dataFP, setDataFP] = useState({});
  const [dataApproval, setDataApproval] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([FakturPenjualanApi.getSingle({ no_faktur })])
      .then(
        Axios.spread((data) => {
          const behavior = data.data.data.behavior ? data.data.data.behavior : "V";
          const { approval, detail } = data.data.data;
          setDataFP(detail);
          setDataApproval(approval ?? []);
          setDataListFP(detail.detail);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const InfoSection = ({ data }) => {
    const InfoItem = ({ title, value, isPaddingTop }) => (
      <tr>
        <td style={{ verticalAlign: "top", paddingTop: isPaddingTop ? "10px" : "0" }}>{title}</td>
        <td className="pl-4 pr-2" style={{ verticalAlign: "top" }}>
          :
        </td>
        <td>
          <span className="text-align-justify">{value}</span>
        </td>
      </tr>
    );

    const InfoItemVertical = ({ title, value }) => (
      <div className="mb-2">
        <small style={{ fontSize: 12 }} className="text-secondary">
          {title}
        </small>
        <div style={{ fontSize: 14 }}>{value}</div>
      </div>
    );

    return (
      <>
        <Row>
          <Col xl>
            <Row>
              <Col md>
                <InfoItemVertical
                  title="Tgl. Sales Order"
                  value={
                    data?.tgl_sales_order
                      ? DateConvert(new Date(data?.tgl_sales_order)).detail
                      : "-"
                  }
                />
                <InfoItemVertical title="No. Sales Order" value={data?.no_sales_order ?? "-"} />
                <InfoItemVertical
                  title="Batas Waktu Pengiriman"
                  value={data?.batas_waktu ? DateConvert(new Date(data?.batas_waktu)).detail : "-"}
                />
              </Col>
              <Col md>
                <InfoItemVertical title="Sales" value={data?.nama_sales ?? "-"} />
                <InfoItemVertical title="Customer" value={data?.nama_customer ?? "-"} />
              </Col>
            </Row>
          </Col>
          <Col xl>
            <InfoItemVertical title="Alamat Pengiriman" value={data?.alamat_pengiriman ?? "-"} />
            <InfoItemVertical
              title="Catatan Sales Order"
              value={data?.catatan_so ?? data?.catatan ?? "-"}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={8} lg={6}>
            <Row>
              <Col md>
                <InfoItemVertical
                  title="Tgl. Faktur Penjualan"
                  value={data?.tgl_faktur ? DateConvert(new Date(data?.tgl_faktur)).detail : "-"}
                />
              </Col>
              <Col md>
                <InfoItemVertical title="No. Faktur Penjualan" value={data?.no_faktur ?? "-"} />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const TableFP = ({ dataTable, dataInfo }) => {
    const values = {
      diskon: dataInfo?.diskon ?? 0,
      ppn: dataInfo?.ppn ?? 0,
    };

    const getTotalFooter = () => {
      const getTotal = () =>
        dataTable.reduce((prev, current) => {
          const total = parseInt(parseFloat(current.qty) * parseInt(current.harga_satuan_jual));

          return prev + total;
        }, 0);

      const getDiskon = () => {
        const total = getTotal();
        const diskon = values.diskon;
        const totalDiskon = (total / 100) * diskon;

        return totalDiskon;
      };

      const getTotalAfterDiskon = () => {
        const total = getTotal();
        const totalDiskon = getDiskon();

        return total - totalDiskon;
      };

      const getPPN = () => {
        const totalAfterDiskon = getTotalAfterDiskon();
        const ppn = values.ppn;
        const totalPPN = (totalAfterDiskon / 100) * ppn;

        return totalPPN;
      };

      const getTotalAfterPPN = () => {
        const ppn = getPPN();
        const totalAfterDiskon = getTotalAfterDiskon();

        return totalAfterDiskon + ppn;
      };

      return {
        total: RupiahConvert(String(parseInt(getTotal()))).detail,
        diskon: RupiahConvert(String(parseInt(getDiskon()))).detail,
        ppn: RupiahConvert(String(parseInt(getPPN()))).detail,
        totalAfterDiskon: RupiahConvert(String(parseInt(getTotalAfterDiskon()))).detail,
        totalAfterPPN: RupiahConvert(String(parseInt(getTotalAfterPPN()))).detail,
      };
    };

    return (
      <>
        <div className="p-1">
          <b>List Barang</b>
        </div>
        <Table>
          <thead className="bg-light">
            <Th>No</Th>
            <Th>Tgl. Penerimaan Surat Jalan</Th>
            <Th>No. Penerimaan Surat Jalan</Th>
            <Th>No. Surat Jalan</Th>
            <Th width={300}>Item Barang</Th>
            <Th>Qty</Th>
            <Th>Harga Satuan</Th>
            <Th>Jumlah</Th>
          </thead>
          <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => (
                <tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>
                    {val.tgl_penerimaan_surat_jalan
                      ? DateConvert(new Date(val.tgl_penerimaan_surat_jalan)).defaultDMY
                      : ""}
                  </Td>
                  <Td>{val.no_penerimaan_surat_jalan}</Td>
                  <Td>{val.no_surat_jalan}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td textRight className="text-nowrap">
                    {val.qty} {val.satuan}
                  </Td>
                  <Td textRight>
                    {RupiahConvert(parseInt(val.harga_satuan_jual ?? 0).toString()).detail}
                  </Td>
                  <Td textRight>
                    {
                      RupiahConvert(
                        String(parseFloat(val.qty ?? 0) * parseInt(val.harga_satuan_jual ?? 0))
                      ).detail
                    }
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            )}
          </tbody>
          <tfoot style={{ fontSize: 13 }}>
            {/* Total */}
            <tr className="bg-light">
              <td colSpan={7} className="p-2 text-right font-weight-bold align-middle">
                Total
              </td>
              <td className="py-2 px-1 text-right font-weight-bold align-middle">
                {getTotalFooter().total}
              </td>
            </tr>
            {/* Diskon */}
            <tr>
              <td colSpan={7} className="py-2 px-1 font-weight-bold text-right">
                Diskon ({parseFloat(values?.diskon ?? 0)}%)
              </td>
              <td className="py-0 px-1 text-right font-weight-bold align-middle">
                {getTotalFooter().diskon}
              </td>
            </tr>
            {/* Total Setelah Diskon */}
            <tr className="bg-light">
              <td colSpan={7} className="p-2 text-right font-weight-bold align-middle">
                Total Setelah Diskon
              </td>
              <td className="py-2 px-1 text-right font-weight-bold align-middle">
                {getTotalFooter().totalAfterDiskon}
              </td>
            </tr>
            {/* PPN */}
            <tr>
              <td colSpan={7} className="py-2 px-1 font-weight-bold text-right">
                PPN ({parseFloat(values?.ppn ?? 0)}%)
              </td>
              <td className="py-0 px-1 text-right font-weight-bold align-middle">
                {getTotalFooter().ppn}
              </td>
            </tr>
            {/* Total Setelah PPN */}
            <tr className="bg-light">
              <td colSpan={7} className="py-2 px-1 text-right font-weight-bold align-middle">
                Total Setelah PPN
              </td>
              <td className="py-2 px-1 text-right font-weight-bold align-middle">
                {getTotalFooter().totalAfterPPN}
              </td>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };
  console.log(dataFP);
  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Faktur Penjualan</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject Faktur Penjualan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"} Faktur Penjualan
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject Faktur Penjualan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: <span className="text-warning">Revise Faktur Penjualan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"} Faktur Penjualan
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    setNavbarTitle("Approval Faktur Penjualan");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Faktur Penjualan</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection data={dataFP} />
              <hr />
            </>
          )}

          {!isPageLoading && <TableFP dataTable={dataListFP} dataInfo={dataFP} />}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan"),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataFP.no_faktur,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataFP?.baseline ?? 0,
          };

          FakturPenjualanApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/faktur-penjualan", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${
                    modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                  } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            <FormCard
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              validateForm={validateForm}
              dirty={dirty}
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailFakturPenjualan;
