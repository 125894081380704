import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import { Alert, DataStatus, BackButton, TextArea, ActionButton } from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert } from "../../../utilities";
import { Formik } from "formik";
import { SalesOrderApi } from "../../../api";

const DetailSalesOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_sales_order, tab = "" } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListSO, setDataListSO] = useState([]);
  const [dataSO, setDataSO] = useState({});
  const [approveStatus, setApproveStatus] = useState("V");
  const [dataApproval, setDataApproval] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([SalesOrderApi.getSingle({ no_sales_order: no_sales_order, id_sales_order: id })])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior ? data.data.data.behavior : "V";
          setDataSO(detail);
          setDataApproval(approval ?? []);
          setDataListSO(detail.detail);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td style={{ verticalAlign: "top" }}>{title}</td>
        <td className="pl-4 pr-2" style={{ verticalAlign: "top" }}>
          :
        </td>
        <td>
          <span className="text-align-justify">{value}</span>
        </td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Sales Order"
                value={dataSO?.tgl_sales_order ? getConvertedDate(dataSO?.tgl_sales_order) : "-"}
              />
              <InfoItem title="No. Sales Order" value={dataSO?.no_sales_order ?? "-"} />
              <InfoItem title="Customer" value={dataSO?.nama_customer ?? "-"} />
              <InfoItem title="Sales" value={dataSO.nama_sales ?? "-"} />
              <InfoItem title="Alamat Pengiriman" value={dataSO?.alamat_pengiriman ?? "-"} />
              <InfoItem
                title="Tgl. Batas Waktu"
                value={DateConvert(new Date(dataSO.batas_waktu)).detail ?? "-"}
              />
              <InfoItem title="Catatan Sales Order" value={dataSO?.catatan_so ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const TableSO = ({ dataSO, dataListSO }) => {
    // GET TOTAL ORDER
    const getTotal = () => dataListSO?.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0);

    // GET TOTAL DISKON
    const getJumlahDiskon = () => {
      let total = getTotal();
      return (total * dataSO.diskon) / 100;
    };

    // GET TOTAL SETELAH DISKON
    const getTotalSetelahDiskon = () => {
      let total = getTotal();
      let jmlDiskon = getJumlahDiskon();
      return total - jmlDiskon;
    };

    // GET TOTAL PPN
    const getJumlahPpn = () => {
      let totalSetelahDiskon = getTotalSetelahDiskon();
      return (totalSetelahDiskon * dataSO.ppn) / 100;
    };

    // GET TOTAL SETELAH PPN
    const getTotalSetelahPpn = () => {
      let totalSetelahDiskon = parseInt(getTotalSetelahDiskon());
      let jumlahPpn = parseInt(getJumlahPpn());
      return totalSetelahDiskon + jumlahPpn;
    };

    return (
      <>
        <div className="p-2 mt-3">
          <b>List Item Sales Order</b>
        </div>

        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center bg-light">
              <tr key="head1">
                <th className="align-middle" style={{ ...tableStyling, width: "30px" }}>
                  No.
                </th>
                <th className="align-middle" style={{ ...tableStyling, width: "20px" }}>
                  Kode Barang
                </th>
                <th className="align-middle" style={tableStyling}>
                  Item Barang
                </th>
                <th className="align-middle" style={tableStyling}>
                  Gudang
                </th>
                <th className="align-middle" style={{ ...tableStyling, width: "50px" }}>
                  Qty SO
                </th>
                <th className="align-middle" style={tableStyling}>
                  Satuan
                </th>
                <th className="align-middle" style={tableStyling}>
                  Harga Satuan
                </th>
                <th className="align-middle" style={tableStyling}>
                  Jumlah
                </th>
              </tr>
            </thead>
            <tbody>
              {dataListSO && dataListSO.length > 0 ? (
                dataListSO.map((val, index) => {
                  val.sub_total = parseInt(val.qty_item) * parseInt(val.harga_satuan_jual);
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td style={tableStyling} className="px-2">
                        {val.kode_barang}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_barang}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_gudang}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {parseInt(val.qty_item)}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_satuan}
                      </td>
                      <td style={tableStyling} className="px-2 text-right">
                        {
                          RupiahConvert(val.harga_satuan_jual ? String(val.harga_satuan_jual) : "-")
                            .detail
                        }
                      </td>
                      <td style={tableStyling} className="px-2 text-right">
                        {RupiahConvert(val.sub_total ? String(val.sub_total) : "-").detail}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center bg-light" colSpan={8}>
                    Tidak Ada Data
                  </td>
                </tr>
              )}
              {dataListSO && dataListSO.length > 0 ? (
                <>
                  <tr className="bg-light">
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}
                    >
                      Total :
                    </td>
                    <td className="text-right" style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getTotal() ? RupiahConvert(String(getTotal())).detail : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}
                    >
                      {`Diskon (${parseInt(dataSO.diskon)}%) : `}
                    </td>
                    <td className="text-right" style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getJumlahDiskon() ? RupiahConvert(String(getJumlahDiskon())).detail : "-"}
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}
                    >
                      Total Setelah Diskon
                    </td>
                    <td className="text-right" style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getTotalSetelahDiskon()
                        ? RupiahConvert(String(getTotalSetelahDiskon())).detail
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}
                    >
                      {`PPN (${parseInt(dataSO.ppn)}%) : `}
                    </td>
                    <td className="text-right" style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getJumlahPpn() ? RupiahConvert(String(getJumlahPpn())).detail : "-"}
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}
                    >
                      Total Setelah Diskon
                    </td>
                    <td className="text-right" style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getTotalSetelahPpn()
                        ? RupiahConvert(String(getTotalSetelahPpn())).detail
                        : "-"}
                    </td>
                  </tr>{" "}
                </>
              ) : (
                <div />
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                  ? "Reject"
                  : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    console.log(approveStatus);

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Sales Order</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {tab !== 'history' && <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Sales Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve Sales Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Sales Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: <span className="text-warning">Revise Sales Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve Sales Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>}
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    setNavbarTitle("Approval Sales Order");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Sales Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {!isPageLoading && <TableSO dataSO={dataSO} dataListSO={dataListSO} />}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan"),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataSO.no_sales_order,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataSO.baseline,
          };

          SalesOrderApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/sales-order", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${modalConfig.type == "APP"
                    ? "Approve"
                    : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                    } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            <FormCard
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              validateForm={validateForm}
              dirty={dirty}
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailSalesOrder;
